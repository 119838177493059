<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard :prevButtonText="updatedPrevButtonText" :nextButtonText="updatedNextButtonText"
          :finishButtonText="updatedFinishButtonText">
          <template slot="header">
            <h3 class="card-title">{{ $t('update.header') }}</h3>
            <h3 class="description">
              {{ $t('update.description') }}
            </h3>
            <div class="container">
              <div :class="{'select':true,'darkMode': darkMode, 'lightMode': !darkMode}">
                <select  :class="{'select_item':true,'lightMode': !darkMode,'darkMode': darkMode}"   @change="handleSelectChange" v-model="selectedSn">
                  <option :value="null" disabled selected hidden>{{ getAllSn.length === 0 ? $t('update.NoUpdate') : $t('update.selectormessage') }}</option>
                  <option v-for="sn in getAllSn" :key="sn" :value="sn">{{ sn }}</option>
                  
                </select>
              </div>
            </div>

          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="mdi mdi-numeric-1-circle-outline mdi-36px"></i>
            </template>
            <first-step ref="step1" @on-validated="onStepValidated"></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="mdi mdi-numeric-2-circle-outline mdi-36px"></i>
            </template>
            <second-step ref="step2" @on-validated="onStepValidated"></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="mdi mdi-numeric-3-circle-outline mdi-36px"></i>
            </template>
            <third-step ref="step3"></third-step>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import FirstStep from './Wizard/FirstStep.vue';
import SecondStep from './Wizard/SecondStep.vue';
import ThirdStep from './Wizard/ThirdStep.vue';
import swal from 'sweetalert2';
import { SimpleWizard, WizardTab } from 'src/components';

export default {
  data() {
    return {
      wizardModel: {},
      selectedSn: null,
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    SimpleWizard,
    WizardTab
  },
  created() {
    if (this.getAllSn.length == 0) {
      this.$store.commit('setSnUpdate', null);
    }
    // console.log("created", this.getAllSn.length);
    if (this.$store.getters.getSnUpdate != null) {
      this.selectedSn = this.$store.getters.getSnUpdate;
      this.handleSelectChange();
  }
  },
  computed: {
    ...mapState(['darkMode']),
        darkMode() {
            return this.$store.getters.getDarkMode;
        },
    updatedPrevButtonText() {
      return this.$t('update.previous');
    },
    updatedNextButtonText() {
      return this.$t('update.next');
    },
    updatedFinishButtonText() {
      return this.$t('update.start');
    },
    getAllSn() {
      
      return this.$store.getters.getSnUpdateList ;
    },
  },
  methods: {
    handleSelectChange() {

      this.$store.dispatch('updateSn', this.selectedSn); 

      // console.log("Selected SN:", this.selectedSn);

    },
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      swal('Good job!', 'You clicked the finish button!', 'success');
    }
  }
 
};
</script>
<style>
.container {
  display: flex;
  justify-content: center;
  align-items: absolute;
}
.darkMode{
  color: #fff !important;
  background-color: #27293D !important;
}
.lightMode{
  color: #000 !important;
  background-color: #fff !important;
}
.select_item {
  /* Reset Select */
  appearance: none;
  outline: 10px red;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;
  
  background-image: none;
  cursor: pointer;
}

/* Remove IE arrow */
.select_item::-ms-expand {
  display: none;
}

/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  border-radius: .25em;
  overflow: hidden;
}

/* Arrow */
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #34495e;
  transition: .25s all ease;
  pointer-events: none;
}
.select.darkMode::after {
  background-color: #34495e !important; /* Dark mode color */
}

.select.lightMode::after {
  background-color: #285891 !important; /* Light mode color */
}
/* Transition */
.select:hover::after {
  color: #285891;
}
</style>
